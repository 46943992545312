import * as React from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  Message,
  Segment,
  InputOnChangeData,
  Container
} from "semantic-ui-react";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {INewPassword, IErrorResponse} from "../../types";
import {t} from "../../i18n";
import queryString from "querystring";
import PasswordStrengthBar from "react-password-strength-bar";
import {toast} from "react-toastify";
import {navigateTo} from "../../helpers/router";
import Cloud from "../backgrounds/others/Cloud";

interface StateProps {
  password: INewPassword & IErrorResponse
}

export function mapStateToProps(state: any) {
  return {
    password: state.newPassword
  };
}

interface DispatchProps {
  newPassword: typeof actions.newPassword
}

const mapDispatchToProps = {
  newPassword: actions.newPassword
};

export interface OwnProps {
  token: string
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  email: string,
  password: string,
  repassword: string,
  button: boolean,
  formErrorKeys?: any,
  formErrors?: any,
  showError: boolean,
  success: boolean,
  passStrong: boolean,
  isPasswordSet: boolean,
}

class NewPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      repassword: "",
      formErrorKeys: {},
      button: false,
      showError: false,
      success: false,
      passStrong: false,
      isPasswordSet: false
    };
  }

  componentDidMount() {
    let search = this.props.router.location.search;
    if (search) {
      let queryParams = search.substr(1);
      queryParams = queryString.parse(queryParams);
      if (queryParams.token) {
        this.state.token = queryParams.token
        this.setState({token: queryParams.token})
      }
    }
  }

  handleResetPasswordForm(e: React.FormEvent): void {
    e.preventDefault();
    if (this.state.password === this.state.repassword) {
      let formData = {
        email: this.state.email,
        password: this.state.password,
        token: this.state.token
      };
      this.setState({showError: false}, () =>
        this.props.newPassword(formData)
      );
    } else {
      this.setState({
        formErrors: ["Hasła nie są identyczne"],
        formErrorKeys: {repassword: true},
        showError: true
      });
    }
  }

  handleInputChange(e: React.ChangeEvent, data: InputOnChangeData): void {
    // @ts-ignore
    this.setState({[data.name]: data.value});
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.password !== prevProps.password) {
      let password = this.props.password;
      if (password.errors) {
        let errors = [];
        let errorKeys = [];
        for (let key in password.errors) {
          errorKeys[key] = true;
          errors.push(password.errors[key]);
        }
        this.setState({
          showError: true,
          formErrorKeys: errorKeys,
          formErrors: errors
        });
      }
      if (password.exception) {
        let errors = [];
        let errorKeys = [];
        for (let key in password.errors) {
          errorKeys[key] = true;
          errors.push(password.errors[key]);
        }
        errors.push(password.message);
        errorKeys["email"] = true;
        this.setState({
          showError: true,
          formErrorKeys: errorKeys,
          formErrors: errors
        });
      }
      if (password.message === "passwords.reset") {
        toast.success(t('passwords.reset'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        this.setState({isPasswordSet: true})
      }
    }
  }

  isPasswordAcceptable(score) {
    if (score >= 1) {
      this.setState({passStrong: true})
    } else {
      this.setState({passStrong: false})
    }
  }

  render() {
    if (!this.state.isPasswordSet) {
      return (
        <Cloud>
          <Segment id="cloud-panel" basic style={{height: "100vh", display: "flex", alignItems: "center"}}>
            <Container>
              <Grid stackable centered padded columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Segment>
                      <Header id="cloud-subheader" textAlign="center">{t('Nowe hasło')}</Header>
                      <Divider section/>
                      <Form
                        onSubmit={this.handleResetPasswordForm.bind(this)}
                        error={this.state.showError}
                      >
                        {t('email')}
                        <Form.Field error={this.state.formErrorKeys.email}>
                          <Input
                            placeholder={t("Email address")}
                            required
                            name="email"
                            type="email"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.email}
                          />
                        </Form.Field>
                        {t('hasło')}
                        <Form.Field error={this.state.formErrorKeys.password}>
                          <Input
                            placeholder={t("Password")}
                            required
                            name="password"
                            type="password"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.password}
                          />
                          <PasswordStrengthBar password={this.state.password}
                                              shortScoreWord={'hasło jest za krótkie'}
                                              minLength={6}
                                              onChangeScore={(score) => this.isPasswordAcceptable(score)}
                          />
                        </Form.Field>
                        <Form.Field error={this.state.formErrorKeys.repassword}>
                          {t('powtórz hasło')}
                          <Input
                            placeholder={t("powtórz hasło")}
                            required
                            name="repassword"
                            type="password"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.repassword}
                          />
                        </Form.Field>
                        <Message
                          error
                          header={t("Popraw błędy formularza")}
                          list={this.state.formErrors}
                        />
                        <Message
                          positive
                          header={t("Sukces")}
                          hidden={!this.state.success}
                          content={t("Pomyślnie zmieniono hasło do twojego konta")}
                        />
                        <Button primary type="submit" disabled={this.state.button}>
                          {t('Zmień hasło')}
                        </Button>
                        <br/>
                        <br/>
                      </Form>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </Cloud>
      );
    } else {
      return (
        <Cloud>
          <Segment id="cloud-panel" style={{height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}} textAlign="center">
            <Container>

              <Grid>
                <Grid.Column textAlign="center">
                  <Header id="cloud-subheader">Nowe hasło zostało ustawione</Header>
                  <Button primary className='btn-primary shadow-light' id='btn-register' onClick={() => navigateTo('/login')}>zaloguj się</Button>              </Grid.Column>
              </Grid>

            </Container>
          </Segment>
        </Cloud>
      )
    }


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);