import * as React from "react"
import FullApp from "../../FullApp";
import Layout from "../../templates/layout";
import NewPassword from "../../components/pages/new-password";

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <NewPassword {...props} router={props}/>
    </Layout>
  </FullApp>
);